import {
  fetchUserContext,
  requestUser,
  receiveUser,
  identifyUser,
  identifyGuestUser,
  receiveIdentifyUser,
  receiveIdentifyGuestUser,
} from '@zola-helpers/client/dist/es/redux/user/userActions';

import _isEmpty from 'lodash/isEmpty';

import type { UserContext } from '@/actions/UserActions.type';

import type { AppThunk } from './types';

export function onIdentifyUser(forceIdentify?: boolean): AppThunk<void> {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const hasIdentified = getState().user.hasIdentified || false;
    if (!userContext.is_guest && (!hasIdentified || forceIdentify)) {
      identifyUser({ userContext, hasIdentified, forceIdentify });
      dispatch(receiveIdentifyUser());
    }
  };
}

export function onIdentifyGuestUser(forceIdentify?: boolean): AppThunk<void> {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const { hasGuestIdentified } = getState().user;
    identifyGuestUser({ userContext, hasGuestIdentified, forceIdentify });
    dispatch(receiveIdentifyGuestUser());
  };
}

export function getUserContext(): AppThunk<Promise<void | UserContext>> {
  return (dispatch) => {
    dispatch(requestUser());
    return (
      fetchUserContext()
        .then((json) => {
          // Cast here is because zola-helpers and web-registry declare this type just a bit differently
          dispatch(receiveUser(json as unknown as UserContext));
          dispatch(onIdentifyUser());
          return json as unknown as UserContext;
        })
        // eslint-disable-next-line no-restricted-syntax
        .catch((err) => console.log('web-nav getUserContext err:', err))
    );
  };
}

export const maybeFetchUser =
  (forceFetch = false): AppThunk<Promise<UserContext | null | void>> =>
  (dispatch, getState) => {
    const state = getState();
    const userInStore = state.user.userContext;
    const isFetchingUser = state.user.busy;

    if (forceFetch || (_isEmpty(userInStore) && !isFetchingUser)) {
      return dispatch(getUserContext());
    }
    return Promise.resolve(userInStore);
  };
